





import { Component } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import PayeesTableComponent from '../components/PayeesTableComponent.vue';
import { VendorCategory } from '../components/PayeeHelper';
import * as features from '../lib/feature-constants';

@Component({
  components: {
    PayeesTableComponent,
  },
})
export default class ClientsVue extends BaseComponent {
  protected baseRoute: string = '/clients';
  protected feature: string = features.clientMenu;
  private category = VendorCategory.Payer;
}
